import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

const media = {
  secTitle: 'Reinforced Racial and Economic Divisions',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/h-ksN44ZO14',
  },
};

export default () => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      Rapid population growth, changing economies, and shifting demographics in
      some thriving Southern cities have reinforced stark racial and economic
      divisions between people — exacerbating long-standing issues related to
      inequality of opportunity, tension between groups, and distrust of local
      government and institutions.
    </p>
    <p>
      While some Southern metropolitan areas continue to grow and expand,
      low-income people in these cities continue to face challenges such as
      inadequate infrastructure, transportation and affordable housing. There is
      a feeling that those who are marginalized are being left out of newfound
      prosperity.
    </p>
    <p>
      Residents asserted that highly educated and well connected white residents
      mostly reap the benefits of economic growth. Many talked about the need
      for cities and local governments to have dedicated, ambitious strategies
      around equity of opportunity.
    </p>
    <p>
      In places facing significant economic and social challenges, residents
      described the pride they feel in being part of a resilient community. They
      also shared their hopes for a more inclusive future, and their frustration
      with the oversimplification and vilification of their circumstances by
      outsiders. People feel pushed out of the places they call home, due to
      limited possibilities for themselves and their families. However, many
      feel a deep commitment to the South.
    </p>
    <p>
      Residents identified the following as the key drivers of barriers to
      opportunity in their communities: systemic and institutional challenges,
      racism, a lack of relationships that support building opportunity within
      the community (both among community members and in relationship to
      government), lack of awareness, lack of trust, lack of empathy, and
      feelings of distrust and powerlessness.
    </p>
    <Quote
      quote="We’re attracting people that are filling these needs but we’re not really catering to people who are already living here."
      person="Black female, 39"
      title="Charlotte"
    />
    <Quote
      quote="There’s so much opportunity if you’re in the right place at the right time and you know the right people."
      person="White female, 29"
      title="Bentonville"
    />
  </Modal>
);
